<template>
  <header class="header-container bg-white flex items-center justify-between text-white"
    :class="{ 'header-z-index': isCollapse }" :style="{ minWidth: viewStore.minViewWidth }">
    <div
      class="flex text-lg rounded hover:bg-hover-grey cursor-pointer text-text-block leading-6 py-1 font-medium header-title"
      @click.stop="handleCheckoutSider">
      <img :src="hamburgerButton" alt="" class="mr-4" />
      <div>{{ toolTitle }}</div>
    </div>
    <div class="flex-right flex items-center position-container">
      <notification v-if="isLogin" />
      <div @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" class="user-info-container">
        <img :src="userAvatar" class="rounded-full cursor-pointer" />
        <div class="menu-list-hover-wrap" v-if="showUserInfo">
          <ul class="menu-list-container">
            <li class=" flex menu-item cursor-item">
              <div class="font-semibold username" @click="handleToPremium">{{ userName }}</div>
              <div class="pro" v-if="planName !== 'Free'">{{ planName }}</div>
            </li>
            <li class="menu-item cursor-item ">
              <div @click="handleResetPwd">Reset Password</div>
            </li>
            <li class="menu-item cursor-item " @click="handleLogout">
              <div>Log out</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import { ref, watchEffect, onMounted } from "vue";
import user_default_avatar from "@/assets/user_default_avatar.svg";
import notification from "@/components/notification";
import { getToolTitleByPath } from "@/components/models/config.js";
import hamburgerButton from "@/assets/hamburger-button.svg";
import { eventTracking } from "@/utils/eventTracking.js";
import { storeToRefs } from "pinia";
import { useSideStore, useUserStore, useViewStore, useSubscriptionInfo } from "@/store";
import { titleToTrackNameJson } from '../data.config.js'
import { isLogin } from "@/utils/auth.js";
import { useRoute, useRouter } from "vue-router";

const userStore = useUserStore()
const subscriptionInfo = useSubscriptionInfo()
const { userName } = storeToRefs(userStore)
const route = useRoute();
const router = useRouter()
const sideStore = useSideStore();
const viewStore = useViewStore()
const { isCollapse } = storeToRefs(sideStore);

const userAvatar = ref(user_default_avatar);
const toolTitle = ref("");
const showUserInfo = ref(false)
const planName = ref("Free")

onMounted(() => {
  eventTracking("booltool_page_view", { tool_name: titleToTrackNameJson[toolTitle.value] })
})

const handleCheckoutSider = () => {
  sideStore.isCollapse = !isCollapse.value;
};

//退出登录
const handleLogout = async () => {
  router.push('/login')
  const res = await userStore.logout()
}

//重置密码
const handleResetPwd = () => {
  router.push({ path: 'resetPwd', query: { step: 1, type: "reset" } })
}

watchEffect(() => {
  toolTitle.value = getToolTitleByPath(route.path);
  if (route.path == "/premium") {
    toolTitle.value = "Upgrade Plan"
  } else if (route.path == "/refund") {
    toolTitle.value = "Request Refund"
  }
});

watchEffect(() => {
  switch (subscriptionInfo.packageCode) {
    case 201101:
    case 201102:
    case 200801:
      planName.value = 'Pro';
      break;
    case 201201:
    case 201202:
    case 200901:
      planName.value = 'Ultra';
      break;
    default:
      planName.value = 'Free';
      break;
  }
});

const handleMouseEnter = () => {
  showUserInfo.value = true
}

const handleMouseLeave = () => {
  showUserInfo.value = false
}

const handleToPremium = () => {
  if (!planName.value) {
    return
  }

  router.push('/premium')
}
</script>

<style lang="less" scoped>
.header-container {
  width: 100%;
  height: 60px;
  padding: 0 22px;
  border-bottom: 1px solid #e9e9e9;
}

.header-z-index {
  z-index: 999;
}

.header-dropdown {
  .ant-dropdown-menu {
    box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
    border-radius: 4px;
  }
}

.username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 107px;
}

.pro {
  height: 20px;
  padding: 0 6px;
  align-items: center;
  background: #060606;
  border-radius: 2px;
  color: #FFE68E;
  line-height: 20px;
  margin-left: 8px;
  text-align: center;
  font-size: 12px;
}

.header-title {
  padding-left: 14px;
  padding-right: 14px;
}

:global(.header-dropdown .ant-dropdown:active) {
  animation: none !important;
}


.position-container {
  position: relative;
}

.menu-list-hover-wrap {
  position: absolute;
  top: 30px;
  right: 0px;
}

.menu-list-container {
  margin-top: 24px;
  border: 1px solid #DEE0E3;
  box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
  border-radius: 4px;
  padding: 8px 0px;
  min-width: 170px;
  border-radius: '4px';
  background-color: #fff;

  .menu-item {
    height: 32px;
    line-height: 32px;
    color: #1F2329;
    padding: 0 12px;
    display: flex;
    align-items: center;


    &:hover {
      background: #EFF0F1;
    }
  }

  .cursor-item {
    cursor: pointer;
  }
}
</style>
